<template>
<div v-if="data">
  <Hero :data="data.header" :show-side-ball="true" :use-colored-heading="true">
    <IllustrationHero class="hero__illustration"/>
  </Hero>
  <div class="block block--violet">
    <Ball class="ball--outlined content__ball"/>
    <div class="width__maxbody content--center">
      <h4 class="text--uppercase subtitle">{{ data.belowfold_subtitle }}</h4>
      <h2 class="heading maxed-heading mt-1">{{ data.belowfold_title }}</h2>
      <div class="mt-6 grid-two--spaced">
        <p>{{ data.belowfold_text_first }}</p>
        <div>
          <p>{{ data.belowfold_text_second }}</p>
          <ButtonTextLink class="mt-2 text--white" :to="data.belowfold_button.link">{{ data.belowfold_button.label }}</ButtonTextLink>
        </div>
      </div>
      <Smile class="content__smile"/>
    </div>
  </div>
  <div class="block block--violet-padded">
    <div class="width__maxbody content--center">
      <h2 class="heading text--center text--red">{{ data.services_heading }}</h2>
      <div class="grid-three mt-4">
        <Service v-for="service in services" :service="service" :key="service.id"/>
      </div>
    </div>
  </div>
  <BlockNextLevel :data="data.next_level_block"/>
  <div class="block">
    <div class="width__maxbody content--center column--centered">
      <h2 class="heading text--center text--red">Portfolio</h2>
      <div class="grid-two mt-4 w-full">
        <PortfolioBlock v-for="project in projects" :key="project.id" :data="project"/>
      </div>
      <ButtonLink class="mt-4" link="/portfolio">Bekijk alle projecten</ButtonLink>
    </div>
  </div>
  <BlockGeneralCTA/>
  <BlockInstagram/>
</div>
</template>

<script lang="ts" setup>
import useStrapiHelper from "~~/composables/strapi";
import IllustrationHero from '~/assets/svg/ill_header_main.svg?component';
import Smile from '~/assets/svg/smile_icon.svg?component';

const { flattenObject, flattenCollection } = useStrapiHelper();
const { find } = useStrapi();

const { data } = await useAsyncData(
  'homePage',
  () => find('home-page', { populate: ['header', 'header.buttons', 'belowfold_button', 'next_level_block', 'next_level_block.button'] }),
  {
    transform: (data) => {
      return flattenObject(data);
    }
  }
)

const { data: services } = await useAsyncData(
  'homepage_services',
  () => find('service-pages', { populate: ['icon']}),
  {
    transform: (data) => {
      return flattenCollection(data);
    }
  }
)

const { data: projects } = await useAsyncData(
  'projects',
  () => find('project-pages', { 
    populate: ['thumbnail'],
    pagination: {
      page: 1,
      pageSize: 6
    },
    sort: ['publishedAt:desc']
  }),
  {
    transform: (data) => {
      return flattenCollection(data);
    }
  }
);

useHead({
  title: 'Jolie design | Design is the silent ambassador of your brand',
  titleTemplate: '%s'
});

const useStrapiMeta = useStrapiSeo();

useStrapiMeta(data, {
  title: 'Jolie design | Design is the silent ambassador of your brand',
  description: data.value?.header.text
});
</script>

<style lang="scss">
.content__smile {
  fill: white;
  height: 5.5rem;
  width: auto;
  margin-top: 4rem;
}

.content__ball {
  position: absolute;
  top: -4rem;
  left: 8rem;

  @include breakpoint(null, 1700px) {
    display: none;
  }
}

.hero__illustration {
  transform: translateY(16rem);
  margin-top: -12rem;
  width: 466px;

  @include breakpoint(null, $breakpoint_lg) {
    width: 260px;
    margin-top: -7rem;
    transform: translateY(11rem)
  }

  @include breakpoint(null, $breakpoint_sm) {
    width: 233px;
    margin-top: -6rem;
    transform: translateY(10rem)
  }
}

.maxed-heading {
  max-width: 52rem;
}
</style>
